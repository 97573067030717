<template><p>Laden...</p></template>

<script>
export default {
    created() {
        window.location.href = 'https://go.dexxter.be/sign-up?accountant=true';
    }
};
</script>

<style lang="scss" scoped></style>
